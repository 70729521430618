@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay-Bold.eot');
  src: url('./assets/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay-Medium.eot');
  src: url('./assets/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Medium.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay-Regular.eot');
  src: url('./assets/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Regular.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  font-family: 'SF Pro Display';
}

h2 {
  font-size: 32px;
  font-weight: bold;
}

.bg-img {
  background: url("./assets/images/upload-web/background.png") center center no-repeat;
  background-size: cover;
  min-height: 100vh;
  /* height: 676px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  overflow: auto;
}

.upload-block {
  width: 960px;
  max-width: 100%;
}

.logo {
  margin-bottom: 30px;
}

.box {
  background: linear-gradient(255.34deg, #9463FF 28.59%, #6B26FF 72.59%);
  box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.2);
  border-radius: 12.5px;
  position: relative;
  padding: 58px 96px 26px;

}

.box:before {
  content: '';
  position: absolute;
  width: 71px;
  height: 68px;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background: url("./assets/images/upload-web/trippy_clipped.png") center bottom no-repeat;
  background-size: contain;
}

.child-box {
  border: 2px solid transparent;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 7.5px;
  position: relative;
  /* max-width: 372px; */
  margin: 0 auto;
}

.child-box-error {
  border: 2px solid #FF453A !important;
}

.child-box-padding {
  padding: 20px 20px 0;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mb-40 {
  margin-bottom: 40px;
}

.title {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.214;
  margin-bottom: 18px;
}

.title span {
  font-weight: 400;
}

.title-message {
  font-family: 'SF Pro Display';
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
}

.child-box .title-message {
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 19px;
}

.para {
  font-family: 'SF Pro Display';
  font-weight: normal;
  font-size: 14px;
  line-height: 1.572;
  min-height: 41px;
  margin-bottom: 4px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.para span {
  display: block;
  font-size: 12px;
  opacity: .8;
}

.btn-custom {
  color: #fff;
  border: 1px solid #fff;
  background: rgb(255 255 255 / 10%);
  font-size: 18px;
  font-weight: 500;
  padding: 11px 25px;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}

.btn-custom:hover {
  background: transparent;
}


.btn-next {
  width: 374px;
  max-width: 100%;
}

.btn-custom.btn-choose {
  padding: 5px 20px;
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.563;
  border-radius: 7.5px;
}

.btn-custom.btn-choose:hover {
  background: rgb(255 255 255 / 30%);
}

.btn-custom.btn-white {
  background: #fff;
  color: #6B26FF;
}

.btn-custom.btn-white:hover {
  color: #fff;
  background: transparent;
}

.box.thank-you-box {
  padding: 190px 100px 80px;
}

.box.thank-you-box .title-message {
  margin-bottom: 76px;
}

.upload-imgblock {
  margin-bottom: 4px;
}

.upload-img {
  height: 60px;
  margin-bottom: 5px;
}

.upload-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.upload-imgblock .para {
  margin-bottom: 0;
  min-height: auto;
}

.login-box .title {
  margin-bottom: 24px;
}

.login-box .title-message {
  max-width: 386px;
  margin: 0 auto 29px;
}

.login-form {
  max-width: 374px;
  margin: 0 auto;
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
}

label {
  line-height: 1.25;
  margin-bottom: 6px;
}

.form-control {
  color: #fff;
  border: 1px solid #fff;
  background: rgb(255 255 255 / 10%);
  padding: 7px 20px;
  border-radius: 7.5px;
}

.form-control-input {
  color: black !important;
  border-style: solid !important;
  border-width: 1px !important; 
  border-color: hsl(0, 0%, 80%) !important;
  background: #fff !important;
  padding: 6px 10px !important;
  border-radius: 4px !important;
}

.error {
  color: #fff;
  border-color: #FF453A !important;
  background: rgb(255 255 255 / 10%);
}

.btn-block {
  margin-top: 75px;
}

.error-1 .css-1s2u09g-control {
  border-color: red !important;
}
/* resposive start  */
@media (max-width: 991px) {
  .box {
    padding: 58px 40px 26px;
  }

  .box.thank-you-box {
    padding: 190px 50px 80px;
  }
}

@media (max-width: 767px) {
  .btn-custom {
    padding: 8px 16px;
  }

  .logo {
    max-width: 180px;
    margin-bottom: 16px;
  }

  .box:before {
    height: 55px;
  }

  .title {
    font-size: 40px;
  }

  .title-message {
    font-size: 18px;
  }

  .box.thank-you-box {
    padding: 100px 30px 60px;
  }

  .box.thank-you-box .title-message {
    margin-bottom: 45px;
  }

  .login-box .title {
    margin-bottom: 18px;
  }

  .btn-block {
    margin-top: 40px;
  }

  .login-box .title-message {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .box:before {
    display: none;
  }

  .bg-img {
    padding: 50px 12px;
  }

  .box {
    padding: 30px 10px 20px;
  }

  .title {
    margin-bottom: 12px;
  }

  .title-message.mb-40 {
    margin-bottom: 30px;
  }

  .box.thank-you-box {
    padding: 80px 10px 50px;
  }

  .box.thank-you-box .title-message {
    margin-bottom: 40px;
  }
}

@media (max-width: 374px) {


  .title {
    font-size: 38px;
  }

  .title-message {
    font-size: 15px;
  }
}